// Begin Firebase specific code

(() => {
    // This code is based on the code at https://github.com/stripe-samples/firebase-subscription-payments
    // And the corresponding post-install instructions at
    // https://github.com/stripe/stripe-firebase-extensions/blob/master/firestore-stripe-payments/POSTINSTALL.md

    // DECLARE/Instantiate variables

    // Set the config object for the Firebase project
    const firebaseConfig = {
        // For ifdef info see https://github.com/nippur72/ifdef-loader
                             
        apiKey: "AIzaSyBfZlJ7s6reZCXbUdTX6NXKhf13Q5nD_IY",
        authDomain: "mycalfeed-for-ofw.firebaseapp.com",
        projectId: "mycalfeed-for-ofw",
        storageBucket: "mycalfeed-for-ofw.appspot.com",
        messagingSenderId: "419088736064",
        appId: "1:419088736064:web:88137898dbabae8d2ce4a5",
        measurementId: "G-9FWX098RWK",
                              
                                                          
                                                            
                                           
                                                           
                                          
                                                           
                                      
                  
    };
    const functionLocation = "us-central1";

    // Initialize Firebase
    //const firebaseApp = initializeApp(firebaseConfig); // v9
    const firebaseApp = firebase.initializeApp(firebaseConfig); // v8
    const db = firebaseApp.firestore(); // TODO: wait on loading this or load async, see "Load services only when they're needed" https://firebase.google.com/docs/web/pwa?hl=en&authuser=0#load_services_only_when_theyre_needed
    const analytics = firebaseApp.analytics();

    // Do authentication with the FirebaseUI; https://firebase.google.com/docs/auth/web/firebaseui
    const firebaseAuthUI = new firebaseui.auth.AuthUI(firebase.auth());

    // Listen for changes in the user's authorization state...
    firebase.auth().onAuthStateChanged((firebaseUser) => {
        // If the user is now known to be a registered user...
        if (firebaseUser) {
            document.querySelector("#firebaseui-auth-container").style.display = "none";
            document.querySelectorAll(".show-when-user-is-authed").forEach((d) => (d.style.display = "block"));
            currentUser = firebaseUser.uid;
            onUserIsKnown();
        }
        // Else the user is not known as a registered user...
        else {
            // Hide the "user-is" content (it is hidden by default, but hide it now in case the user was just signed out)
            document
                .querySelectorAll(".show-when-user-is-authed,.hide-when-user-is-authed,.show-when-user-is-cust,.hide-when-user-is-cust")
                .forEach((e) => (e.style.display = "none"));
            document.querySelector("#firebaseui-auth-container").style.display = "block";

            // Set up Firebase auth ui config object
            // See https://github.com/firebase/firebaseui-web
            // and https://firebase.google.com/docs/auth/web/firebaseui
            var authUiConfig = {
                callbacks: {
                    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                        // User successfully signed in.
                        // Return type determines whether we continue the redirect automatically
                        // or whether we leave that to developer to handle.
                        return true;
                    },
                    uiShown: () => {
                        document.querySelector("#loader").style.display = "none";
                    },
                },
                signInFlow: "popup",
                signInSuccessUrl: window.location.href,
                signInOptions: [
                    // See https://firebase.google.com/docs/auth/web/firebaseui#set_up_sign-in_methods
                    {
                        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                    },
                    {
                        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                        customParameters: {
                            prompt: "select_account", // Force account selection even when one account is available
                        },
                    },
                ],
                credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                tosUrl: "https://mycalfeed-for-ofw.crownaddons.com/mycalfeed-terms-of-service", // Your terms of service url.
                privacyPolicyUrl: "https://mycalfeed-for-ofw.crownaddons.com/mycalfeed-privacy-policy", // Your privacy policy url.
            };

            // start the Firebase Auth UI with the config object
            firebaseAuthUI.start("#firebaseui-auth-container", authUiConfig);

            // Change the buttons' text
            function changeSignInBtnText() {
                var btns = document.querySelectorAll(".firebaseui-idp-text-long");
                if (btns.length == 0 || btns[btns.length - 1].innerText.indexOf("Sign in with") == -1) setTimeout(changeSignInBtnText, 50);
                else
                    btns.forEach((s) => {
                        s.innerText = s.innerText.replace("Sign in with", "Sign up/in with");
                    });
            }
            changeSignInBtnText();
        }
    });

    // Listen for Signout button click
    document.getElementById("signout").addEventListener("click", () => firebase.auth().signOut());

    const prices = {};
    let subscriptionId;

    // Function for the authentication user-is-known code to call/execute
    function onUserIsKnown() {
        document.querySelector("#products").innerHTML = "";
        // Get/listen-to active products so they can be displayed
        db.collection("products")
            .where("active", "==", true)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    const productData = doc.data();

                    if (!"content" in document.createElement("template")) {
                        console.error("Your browser doesn’t support HTML template elements.");
                        return;
                    }

                    const productNode = document.querySelector("#productTemplate").content.cloneNode(true);

                    // productNode.querySelector("h2").innerText = ""; // productData.name.toUpperCase();
                    // productNode.querySelector(".description").innerText = ""; // productData.description?.toUpperCase() || "";
                    // if (productData.images.length) {
                    //     const img = productNode.querySelector("img");
                    //     img.src = productData.images[0];
                    //     img.alt = productData.name;
                    // }

                    // Set up active prices dropdown list
                    const priceSnap = await doc.ref.collection("prices").where("active", "==", true).orderBy("unit_amount").get();
                    priceSnap.docs.forEach((doc) => {
                        // Save price info for reference during checkout
                        const priceId = doc.id;
                        const priceData = doc.data();
                        prices[priceId] = priceData;
                        // Set up price radio input option
                        const priceOption = document.querySelector("#priceTemplate").content.cloneNode(true);
                        priceOption.querySelector(".price-input").value = priceId;
                        // Set up corresponding label
                        priceOption.querySelector(".price-label").innerText = `${new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: priceData.currency,
                        }).format((priceData.unit_amount / 100).toFixed(2))} per ${priceData.interval ?? "once"}`;

                        productNode.querySelector("#price").appendChild(priceOption);
                    });

                    const form = productNode.querySelector("form");
                    form.addEventListener("submit", onSubscribeToProduct);

                    document.querySelector("#products").appendChild(productNode);
                });
            });

        // Get/listen-to the customer's subscription so it can be displayed
        db.collection("customers")
            .doc(currentUser)
            .collection("subscriptions")
            .where("status", "in", ["trialing", "active"])
            .onSnapshot(async (snapshot) => {
                // If no subscriptions...
                if (snapshot.empty) {
                    // Show products
                    document.querySelectorAll(".hide-when-user-is-cust").forEach((e) => (e.style.display = "block"));
                    return;
                }
                // Else this person has a subscription
                document.querySelectorAll(".hide-when-user-is-cust").forEach((e) => (e.style.display = "none"));
                document.querySelectorAll(".show-when-user-is-cust").forEach((e) => (e.style.display = "block"));

                // In this implementation we only expect one Subscription to exist
                subscriptionId = snapshot.docs[0].id;
                const subscription = snapshot.docs[0].data();
                const priceData = (await subscription.price.get()).data();
                document.querySelector("#subscriptionDisplay p").textContent = `You are paying ${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: priceData.currency,
                }).format((priceData.unit_amount / 100).toFixed(2))} per ${
                    priceData.interval
                } for your private iCalendar/iCal/ICS feed of your OFW calendar entries.`; //, giving you the role: ${await getUserCustomerRole()}.`;
            });
    } //end onUserIsKnown()

    // Function for the subscribe-to-product button's code to call/execute
    async function onSubscribeToProduct(event) {
        event.preventDefault();
        document.querySelectorAll("button").forEach((b) => {
            b.disabled = true;
            b.style.width = b.clientWidth + "px";
            b.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"/>';
        });
        const formData = new FormData(event.target);
        const selectedPrice = {
            price: formData.get("price"),
        };
        // For prices with metered billing we need to omit the quantity parameter.
        // For all other prices we set quantity to 1.
        if (prices[selectedPrice.price]?.recurring?.usage_type !== "metered") selectedPrice.quantity = 1;
        const checkoutSession = {
            automatic_tax: true,
            tax_id_collection: true,
            collect_shipping_address: true,
            allow_promotion_codes: true,
            line_items: [selectedPrice],
            success_url: window.location.href,
            cancel_url: window.location.href,
            metadata: {
                key: "value",
            },
        };
        // For one time payments set mode to payment.
        if (prices[selectedPrice.price]?.type === "one_time") {
            checkoutSession.mode = "payment";
            checkoutSession.payment_method_types = ["card", "sepa_debit", "sofort"];
        }

        const docRef = await db.collection("customers").doc(currentUser).collection("checkout_sessions").add(checkoutSession);
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and then inspect your function logs.
                alert(`An error occured: ${error.message}`);
                document.querySelectorAll("button").forEach((b) => (b.disabled = false));
            }
            if (url) {
                window.location.assign(url);
                // NOTE: To customize the checkout UI, see https://dashboard.stripe.com/settings/branding
            }
        });
    }

    // Listen for customer portal button click
    document.querySelectorAll(".cust-portal-link").forEach((cpl) => {
        cpl.addEventListener("click", async (event) => {
            document.querySelectorAll("button").forEach((b) => {
                b.disabled = true;
                b.style.width = b.clientWidth + "px";
                b.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"/>';
            });
            // Call cloud function to get customer portal url
            const createPortalLink = firebase
                .app()
                .functions(functionLocation)
                .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
            const { data } = await createPortalLink({ returnUrl: window.location.href });
            window.location.assign(data.url);
            // NOTE: To customize the portal UI, see https://dashboard.stripe.com/settings/branding
        });
    });

    // Listen for submit-ofw-credentials button
    document.querySelector("#formGetPriviCalICSaddr").addEventListener("submit", (event) => {
        event.preventDefault();
        let btn = document.querySelector("#subOfwUnPw");
        let btnHtml = btn.innerHTML;
        btn.disabled = true;
        btn.style.width = btn.clientWidth + "px";
        btn.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"/>';
        document.querySelector("#priviCalICSAddr").value = "Please wait...";

        function restoreBtn() {
            btn.innerHTML = btnHtml;
            btn.disabled = false;
        }

        const getOfwIcsUrl = firebase.app().functions(functionLocation).httpsCallable("getOfwIcsUrl");
        getOfwIcsUrl({
            origin: document.location.origin,
            subId: subscriptionId,
            ofwun: event.target.elements["ofw1"].value,
            ofwpw: event.target.elements["ofw2"].value,
            ofwde: event.target.elements["ofw3"].value,
        })
            .then((result) => {
                let inp = document.querySelector("#priviCalICSAddr");
                inp.value = result.data.url;
                restoreBtn();
                btn.classList.remove("btn-primary");
                btn.classList.add("btn-secondary");
                btnCopy = document.querySelector("#copyOfwCalUrl");
                btnCopy.classList.remove("btn-secondary");
                btnCopy.classList.add("btn-primary");
                inp.focus();
                inp.select();
            })
            .catch((error) => {
                console.error(error);
                document.querySelector("#priviCalICSAddr").value = "ERROR: " + JSON.stringify(error);
                restoreBtn();
                btn.classList.remove("btn-secondary");
                btn.classList.add("btn-primary");
                btnCopy = document.querySelector("#copyOfwCalUrl");
                btnCopy.classList.remove("btn-primary");
                btnCopy.classList.add("btn-secondary");
            });
    });

    // Copy feed address button
    //document.querySelector('#copyOfwCalUrl').tooltip();
    document.getElementById("copyOfwCalUrl").addEventListener("click", () => {
        let inp = document.querySelector("#priviCalICSAddr");
        inp.focus();
        inp.select();
        navigator.clipboard.writeText(inp.value);
    });

    // Get the user's customer role
    // async function getUserCustomerRole() {
    //     await firebase.auth().currentUser.getIdToken(true);
    //     const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    //     return decodedToken.claims.stripeRole;
    // }
})();
